@font-face {
    font-family: 'HanWangLiSu';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url(./HanWangLiSuMedium.subset.ttf) format('truetype');
}

.HanWangLiSu {
    font-family: 'HanWangLiSu', sans-serif;
    font-style: normal !important;
    font-weight: normal !important;
}