html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: column;
}

#root {
    display: flex;
    flex: 1;
}
