@charset "UTF-8";
:root {
  --surface-a:#1e1e1e;
  --surface-b:#121212;
  --surface-c:hsla(0,0%,100%,.04);
  --surface-d:hsla(0,0%,100%,.12);
  --surface-e:#1e1e1e;
  --surface-f:#262626;
  --text-color:rgba(255, 255, 255, 0.87);
  --text-color-secondary:textSecondaryColor;
  --primary-color:#9FA8DA;
  --primary-color-text:#121212;
  --font-family:Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; 
}

/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("./fonts/roboto-v20-latin-ext_latin-regular.woff2") format("woff2"), url("./fonts/roboto-v20-latin-ext_latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("./fonts/roboto-v20-latin-ext_latin-500.woff2") format("woff2"), url("./fonts/roboto-v20-latin-ext_latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }
/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("./fonts/roboto-v20-latin-ext_latin-700.woff2") format("woff2"), url("./fonts/roboto-v20-latin-ext_latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }
* {
  box-sizing: border-box; }

.p-component {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 12px;
  font-weight: normal; }

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.32);
  transition-duration: 0.2s; }

.p-disabled, .p-component:disabled {
  opacity: 0.38; }

.p-error, .p-invalid {
  color: #f44435; }

.p-text-secondary {
  color: rgba(255, 255, 255, 0.6); }

.pi {
  font-size: 1rem; }

.p-link {
  font-size: 1rem;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  border-radius: 4px; }
  .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }

.p-autocomplete .p-autocomplete-loader {
  right: 1rem; }
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 4rem; }
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  padding: 0.5rem 1rem; }
  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: rgba(255, 255, 255, 0.6); }
  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #9FA8DA; }
  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.5rem 0; }
    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
      font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.87);
      padding: 0;
      margin: 0; }
  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    background: rgba(159, 168, 218, 0.16);
    color: #9FA8DA;
    border-radius: 4px; }
    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
      margin-left: 0.5rem; }
.p-autocomplete.p-error > .p-inputtext, .p-autocomplete.p-invalid > .p-inputtext {
  border-color: #f44435; }

.p-autocomplete-panel {
  background: #2b2b2b;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0; }
    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
      margin: 0;
      padding: 1rem 1rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: none;
      border-radius: 0; }
      .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
        color: rgba(255, 255, 255, 0.87);
        background: rgba(255, 255, 255, 0.04); }
      .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
        color: #9FA8DA;
        background: rgba(159, 168, 218, 0.16); }

.p-calendar.p-error > .p-inputtext, .p-calendar.p-invalid > .p-inputtext {
  border-color: #f44435; }

.p-datepicker {
  padding: 0.5rem;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px; }
  .p-datepicker:not(.p-datepicker-inline) {
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }
    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
      background: #2b2b2b; }
  .p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: rgba(255, 255, 255, 0.87);
    background: #1e1e1e;
    font-weight: 500;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .p-datepicker .p-datepicker-header .p-datepicker-prev,
    .p-datepicker .p-datepicker-header .p-datepicker-next {
      width: 2.5rem;
      height: 2.5rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
      .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
      .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
        color: rgba(255, 255, 255, 0.6);
        border-color: transparent;
        background: rgba(255, 255, 255, 0.04); }
      .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
      .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
    .p-datepicker .p-datepicker-header .p-datepicker-title {
      line-height: 2.5rem; }
      .p-datepicker .p-datepicker-header .p-datepicker-title select {
        transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
        .p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
          border-color: #9FA8DA; }
      .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        margin-right: 0.5rem; }
  .p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0; }
    .p-datepicker table th {
      padding: 0.5rem; }
      .p-datepicker table th > span {
        width: 2.5rem;
        height: 2.5rem; }
    .p-datepicker table td {
      padding: 0.5rem; }
      .p-datepicker table td > span {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        transition: none;
        border: 1px solid transparent; }
        .p-datepicker table td > span.p-highlight {
          color: #9FA8DA;
          background: rgba(159, 168, 218, 0.16); }
        .p-datepicker table td > span:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none; }
      .p-datepicker table td.p-datepicker-today > span {
        background: transparent;
        color: rgba(255, 255, 255, 0.87);
        border-color: rgba(255, 255, 255, 0.5); }
        .p-datepicker table td.p-datepicker-today > span.p-highlight {
          color: #9FA8DA;
          background: rgba(159, 168, 218, 0.16); }
  .p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.12); }
    .p-datepicker .p-datepicker-buttonbar .p-button {
      width: auto; }
  .p-datepicker .p-timepicker {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    padding: 0.5rem; }
    .p-datepicker .p-timepicker button {
      width: 2.5rem;
      height: 2.5rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
      .p-datepicker .p-timepicker button:enabled:hover {
        color: rgba(255, 255, 255, 0.6);
        border-color: transparent;
        background: rgba(255, 255, 255, 0.04); }
      .p-datepicker .p-timepicker button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
      .p-datepicker .p-timepicker button:last-child {
        margin-top: .2em; }
    .p-datepicker .p-timepicker span {
      font-size: 1.25rem; }
    .p-datepicker .p-timepicker > div {
      padding: 0 0.5rem; }
  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none; }
  .p-datepicker .p-monthpicker {
    margin: 0.5rem 0; }
    .p-datepicker .p-monthpicker .p-monthpicker-month {
      padding: 0.5rem;
      transition: none;
      border-radius: 4px; }
      .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
        color: #9FA8DA;
        background: rgba(159, 168, 218, 0.16); }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0; }
    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
      padding-left: 0; }
    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
      padding-right: 0;
      border-right: 0 none; }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.04); }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.04); }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }

.p-datepicker-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.32); }

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0; } }
.p-checkbox {
  width: 18px;
  height: 18px; }
  .p-checkbox .p-checkbox-box {
    border: 2px solid rgba(255, 255, 255, 0.7);
    background: #1e1e1e;
    width: 18px;
    height: 18px;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 4px;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      transition-duration: 0.2s;
      color: #121212;
      font-size: 14px; }
    .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
      border-color: rgba(255, 255, 255, 0.6); }
    .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: #9FA8DA; }
    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: #9FA8DA;
      background: #9FA8DA; }
      .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
        border-color: #9FA8DA;
        background: #9FA8DA;
        color: #121212; }
  .p-checkbox.p-error > .p-checkbox-box, .p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #f44435; }

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: rgba(255, 255, 255, 0.06); }
  .p-input-filled .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08); }
  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #9FA8DA; }
    .p-input-filled .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
      background: #9FA8DA; }

.p-chips .p-chips-multiple-container {
  font-size: 12px;
  padding: 0.5rem 0.5rem; }
  .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: rgba(255, 255, 255, 0.6); }
  .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #9FA8DA; }
  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    background: rgba(159, 168, 218, 0.16);
    color: #9FA8DA;
    border-radius: 4px; }
    .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
      margin-left: 0.5rem; }
  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.5rem 0; }
    .p-chips .p-chips-multiple-container .p-chips-input-token input {
      font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.87);
      padding: 0;
      margin: 0; }
.p-chips.p-error > .p-inputtext, .p-chips.p-invalid > .p-inputtext {
  border-color: #f44435; }

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem; }

.p-colorpicker-panel {
  background: #2b2b2b;
  border-color: #1e1e1e; }
  .p-colorpicker-panel .p-colorpicker-color-handle,
  .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #ffffff; }

.p-colorpicker-overlay-panel {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }

.p-dropdown {
  background: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px; }
  .p-dropdown:not(.p-disabled):hover {
    border-color: rgba(255, 255, 255, 0.6); }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #9FA8DA; }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 2rem; }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none; }
    .p-dropdown .p-dropdown-label.p-placeholder {
      color: rgba(255, 255, 255, 0.6); }
    .p-dropdown .p-dropdown-label:enabled:focus {
      outline: 0 none;
      box-shadow: none; }
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: rgba(255, 255, 255, 0.6);
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .p-dropdown .p-dropdown-clear-icon {
    color: rgba(255, 255, 255, 0.6);
    right: 2.357rem; }
  .p-dropdown.p-error, .p-dropdown.p-invalid {
    border-color: #f44435; }

.p-dropdown-panel {
  background: #2b2b2b;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .p-dropdown-panel .p-dropdown-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
    background: #2b2b2b;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      padding-right: 2rem; }
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
      right: 1rem;
      color: rgba(255, 255, 255, 0.6); }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0; }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      margin: 0;
      padding: 1rem 1rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: none;
      border-radius: 0; }
      .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: #9FA8DA;
        background: rgba(159, 168, 218, 0.16); }
      .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: rgba(255, 255, 255, 0.87);
        background: rgba(255, 255, 255, 0.04); }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
      padding: 1rem 1rem;
      color: rgba(255, 255, 255, 0.87);
      background: transparent; }

.p-input-filled .p-dropdown {
  background: rgba(255, 255, 255, 0.06); }
  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08); }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: rgba(255, 255, 255, 0.1); }

.p-editor-container .p-editor-toolbar {
  background: #1e1e1e;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
  .p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid rgba(255, 255, 255, 0.12); }
    .p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
      stroke: rgba(255, 255, 255, 0.6); }
    .p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
      fill: rgba(255, 255, 255, 0.6); }
    .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
      border: 0 none;
      color: rgba(255, 255, 255, 0.6); }
      .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
        color: rgba(255, 255, 255, 0.87); }
        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
          stroke: rgba(255, 255, 255, 0.87); }
        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
          fill: rgba(255, 255, 255, 0.87); }
    .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
      color: rgba(255, 255, 255, 0.87); }
      .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
        stroke: rgba(255, 255, 255, 0.87); }
      .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
        fill: rgba(255, 255, 255, 0.87); }
    .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      background: #2b2b2b;
      border: 0 none;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
      border-radius: 4px; }
      .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
        color: rgba(255, 255, 255, 0.87);
        padding: 1rem 1rem;
        border-radius: 4px; }
        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
          color: rgba(255, 255, 255, 0.87);
          background: rgba(255, 255, 255, 0.04); }
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
  .p-editor-container .p-editor-content.ql-snow {
    border: 1px solid rgba(255, 255, 255, 0.12); }
  .p-editor-container .p-editor-content .ql-editor {
    background: #1e1e1e;
    color: rgba(255, 255, 255, 0.87);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: rgba(255, 255, 255, 0.87); }
  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: rgba(255, 255, 255, 0.87); }
  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: rgba(255, 255, 255, 0.87); }
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #9FA8DA; }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #9FA8DA; }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #9FA8DA; }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #9FA8DA; }

.p-inputgroup-addon {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.6);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem 1rem;
  min-width: 2.357rem; }
  .p-inputgroup-addon:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.3); }

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0; }
  .p-inputgroup > .p-component + .p-inputgroup-addon,
  .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
  .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none; }
  .p-inputgroup > .p-component:focus,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
  .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1; }
    .p-inputgroup > .p-component:focus ~ label,
    .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
    .p-inputgroup > .p-float-label > .p-component:focus ~ label {
      z-index: 1; }

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.p-fluid .p-inputgroup .p-button {
  width: auto; }
  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: auto; }

.p-inputnumber.p-error > .p-inputtext, .p-inputnumber.p-invalid > .p-inputtext {
  border-color: #f44435; }

.p-inputswitch {
  width: 2.75rem;
  height: 1rem; }
  .p-inputswitch .p-inputswitch-slider {
    background: rgba(255, 255, 255, 0.3);
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-radius: 0.5rem; }
    .p-inputswitch .p-inputswitch-slider:before {
      background: #bdbdbd;
      width: 1.5rem;
      height: 1.5rem;
      left: -1px;
      margin-top: -0.75rem;
      border-radius: 50%;
      transition-duration: 0.2s; }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.5rem); }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: rgba(255, 255, 255, 0.3); }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: rgba(159, 168, 218, 0.5); }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      background: #9FA8DA; }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: rgba(159, 168, 218, 0.5); }
  .p-inputswitch.p-error, .p-inputswitch.p-invalid {
    border-color: #f44435; }

.p-inputtext {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.87);
  background: #1e1e1e;
  padding: 1rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  appearance: none;
  border-radius: 4px; }
  .p-inputtext:enabled:hover {
    border-color: rgba(255, 255, 255, 0.6); }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #9FA8DA; }
  .p-inputtext.p-error, .p-inputtext.p-invalid {
    border-color: #f44435; }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.875rem 0.875rem; }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 1.25rem 1.25rem; }

.p-float-label > label {
  left: 1rem;
  color: rgba(255, 255, 255, 0.6);
  transition-duration: 0.2s; }

.p-input-icon-left > i:first-of-type {
  left: 1rem;
  color: rgba(255, 255, 255, 0.6); }

.p-input-icon-left > .p-inputtext {
  padding-left: 3rem; }

.p-input-icon-right > i:last-of-type {
  right: 1rem;
  color: rgba(255, 255, 255, 0.6); }

.p-input-icon-right > .p-inputtext {
  padding-right: 3rem; }

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6); }

:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6); }

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6); }

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6); }

.p-input-filled .p-inputtext {
  background-color: rgba(255, 255, 255, 0.06); }
  .p-input-filled .p-inputtext:enabled:hover {
    background-color: rgba(255, 255, 255, 0.08); }
  .p-input-filled .p-inputtext:enabled:focus {
    background-color: rgba(255, 255, 255, 0.1); }

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.875rem 0.875rem; }

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 1.25rem 1.25rem; }

.p-listbox {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px; }
  .p-listbox .p-listbox-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
    background: #1e1e1e;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .p-listbox .p-listbox-header .p-listbox-filter {
      padding-right: 2rem; }
    .p-listbox .p-listbox-header .p-listbox-filter-icon {
      right: 1rem;
      color: rgba(255, 255, 255, 0.6); }
  .p-listbox .p-listbox-list {
    padding: 0; }
    .p-listbox .p-listbox-list .p-listbox-item {
      margin: 0;
      padding: 1rem 1rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      transition: none;
      border-radius: 0; }
      .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
        color: #9FA8DA;
        background: rgba(159, 168, 218, 0.16); }
      .p-listbox .p-listbox-list .p-listbox-item:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
    .p-listbox .p-listbox-list .p-listbox-empty-message {
      padding: 1rem 1rem;
      color: rgba(255, 255, 255, 0.87);
      background: transparent; }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(255, 255, 255, 0.04); }
  .p-listbox.p-error, .p-listbox.p-invalid {
    border-color: #f44435; }

.p-multiselect {
  background: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px; }
  .p-multiselect:not(.p-disabled):hover {
    border-color: rgba(255, 255, 255, 0.6); }
  .p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #9FA8DA; }
  .p-multiselect .p-multiselect-label {
    padding: 1rem 1rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
    .p-multiselect .p-multiselect-label.p-placeholder {
      color: rgba(255, 255, 255, 0.6); }
  .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: rgba(255, 255, 255, 0.6);
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .p-multiselect.p-error, .p-multiselect.p-invalid {
    border-color: #f44435; }

.p-multiselect-panel {
  background: #2b2b2b;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .p-multiselect-panel .p-multiselect-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
    background: #2b2b2b;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
      padding-right: 2rem; }
    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
      right: 1rem;
      color: rgba(255, 255, 255, 0.6); }
    .p-multiselect-panel .p-multiselect-header .p-checkbox {
      margin-right: 0.5rem; }
    .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
      margin-left: 0.5rem;
      width: 2.5rem;
      height: 2.5rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
      .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
        color: rgba(255, 255, 255, 0.6);
        border-color: transparent;
        background: rgba(255, 255, 255, 0.04); }
      .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
  .p-multiselect-panel .p-multiselect-items {
    padding: 0; }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
      margin: 0;
      padding: 1rem 1rem;
      border: 0 none;
      color: rgba(255, 255, 255, 0.87);
      background: transparent;
      transition: none;
      border-radius: 0; }
      .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        color: #9FA8DA;
        background: rgba(159, 168, 218, 0.16); }
      .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        color: rgba(255, 255, 255, 0.87);
        background: rgba(255, 255, 255, 0.04); }
      .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
      .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
        margin-right: 0.5rem; }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
      padding: 1rem 1rem;
      color: rgba(255, 255, 255, 0.87);
      background: transparent; }

.p-input-filled .p-multiselect {
  background: rgba(255, 255, 255, 0.06); }
  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08); }
  .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: rgba(255, 255, 255, 0.1); }

.p-password-panel {
  padding: 1rem;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px; }
  .p-password-panel .p-password-meter {
    margin-bottom: 0.5rem; }

.p-radiobutton {
  width: 20px;
  height: 20px; }
  .p-radiobutton .p-radiobutton-box {
    border: 2px solid rgba(255, 255, 255, 0.7);
    background: #1e1e1e;
    width: 20px;
    height: 20px;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 50%;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
      border-color: rgba(255, 255, 255, 0.6); }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: #9FA8DA; }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
      width: 10px;
      height: 10px;
      transition-duration: 0.2s;
      background-color: #9FA8DA; }
    .p-radiobutton .p-radiobutton-box.p-highlight {
      border-color: #9FA8DA;
      background: #121212; }
      .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #9FA8DA;
        background: #121212;
        color: #9FA8DA; }
  .p-radiobutton.p-error > .p-radiobutton-box, .p-radiobutton.p-invalid > .p-radiobutton-box {
    border-color: #f44435; }

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: rgba(255, 255, 255, 0.06); }
  .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08); }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #121212; }
    .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
      background: #121212; }

.p-rating .p-rating-icon {
  color: #9FA8DA;
  margin-left: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  font-size: 1.143rem; }
  .p-rating .p-rating-icon.p-rating-cancel {
    color: #f44435; }
  .p-rating .p-rating-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }
  .p-rating .p-rating-icon:first-child {
    margin-left: 0; }
  .p-rating .p-rating-icon.pi-star {
    color: #9FA8DA; }
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #9FA8DA; }
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #f44435; }

.p-selectbutton .p-button {
  background: #2f2f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: rgba(255, 255, 255, 0.6); }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #373737;
    border-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87); }
    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6); }
  .p-selectbutton .p-button.p-highlight {
    background: #1c1c1c;
    border-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87); }
    .p-selectbutton .p-button.p-highlight .p-button-icon-left,
    .p-selectbutton .p-button.p-highlight .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6); }
    .p-selectbutton .p-button.p-highlight:hover {
      background: #262626;
      border-color: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.87); }
      .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
      .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
        color: rgba(255, 255, 255, 0.6); }
.p-selectbutton.p-error > .p-button, .p-selectbutton.p-invalid > .p-button {
  border-color: #f44435; }

.p-slider {
  background: rgba(255, 255, 255, 0.3);
  border: 0 none;
  border-radius: 4px; }
  .p-slider.p-slider-horizontal {
    height: 2px; }
    .p-slider.p-slider-horizontal .p-slider-handle {
      margin-top: -10px;
      margin-left: -10px; }
  .p-slider.p-slider-vertical {
    width: 2px; }
    .p-slider.p-slider-vertical .p-slider-handle {
      margin-left: -10px;
      margin-bottom: -10px; }
  .p-slider .p-slider-handle {
    height: 20px;
    width: 20px;
    background: #9FA8DA;
    border: 0 none;
    border-radius: 50%;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
    .p-slider .p-slider-handle:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-slider .p-slider-range {
    background: #9FA8DA; }
  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #9FA8DA;
    border-color: 0 none; }

.p-togglebutton.p-button {
  background: #2f2f2f;
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
  .p-togglebutton.p-button .p-button-icon-left,
  .p-togglebutton.p-button .p-button-icon-right {
    color: rgba(255, 255, 255, 0.6); }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #373737;
    border-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87); }
    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6); }
  .p-togglebutton.p-button.p-highlight {
    background: #1c1c1c;
    border-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87); }
    .p-togglebutton.p-button.p-highlight .p-button-icon-left,
    .p-togglebutton.p-button.p-highlight .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6); }
    .p-togglebutton.p-button.p-highlight:hover {
      background: #262626;
      border-color: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.87); }
      .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
      .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
        color: rgba(255, 255, 255, 0.6); }
  .p-togglebutton.p-button.p-error > .p-button, .p-togglebutton.p-button.p-invalid > .p-button {
    border-color: #f44435; }

.p-button {
  color: #121212;
  background: #9FA8DA;
  border: 0 none;
  padding: 0.714rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px; }
  .p-button:enabled:hover {
    background: rgba(159, 168, 218, 0.92);
    color: #121212;
    border-color: transparent; }
  .p-button:enabled:active {
    background: rgba(159, 168, 218, 0.68);
    color: #121212;
    border-color: transparent; }
  .p-button.p-button-outlined {
    background-color: transparent;
    color: #9FA8DA;
    border: 1px solid; }
    .p-button.p-button-outlined:enabled:hover {
      background: rgba(159, 168, 218, 0.04);
      color: #9FA8DA;
      border: 1px solid; }
    .p-button.p-button-outlined:enabled:active {
      background: rgba(159, 168, 218, 0.16);
      color: #9FA8DA;
      border: 1px solid; }
  .p-button.p-button-text {
    background-color: transparent;
    color: #9FA8DA;
    border-color: transparent; }
    .p-button.p-button-text:enabled:hover {
      background: rgba(159, 168, 218, 0.04);
      color: #9FA8DA;
      border-color: transparent; }
    .p-button.p-button-text:enabled:active {
      background: rgba(159, 168, 218, 0.16);
      color: #9FA8DA;
      border-color: transparent; }
    .p-button.p-button-text.p-button-plain {
      color: rgba(255, 255, 255, 0.6); }
      .p-button.p-button-text.p-button-plain:enabled:hover {
        background: rgba(255, 255, 255, 0.04);
        color: rgba(255, 255, 255, 0.6); }
      .p-button.p-button-text.p-button-plain:enabled:active {
        background: rgba(255, 255, 255, 0.16);
        color: rgba(255, 255, 255, 0.6); }
  .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }
  .p-button .p-button-icon-left {
    margin-right: 0.5rem; }
  .p-button .p-button-icon-right {
    margin-left: 0.5rem; }
  .p-button .p-button-icon-bottom {
    margin-top: 0.5rem; }
  .p-button .p-button-icon-top {
    margin-bottom: 0.5rem; }
  .p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #9FA8DA;
    background-color: #121212; }
  .p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .p-button.p-button-rounded {
    border-radius: 2rem; }
  .p-button.p-button-icon-only {
    width: auto;
    padding: 0.714rem; }
    .p-button.p-button-icon-only .p-button-icon-left,
    .p-button.p-button-icon-only .p-button-icon-right {
      margin: 0; }
    .p-button.p-button-icon-only.p-button-rounded {
      border-radius: 50%;
      height: 3rem; }
  .p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.62475rem 0.875rem; }
    .p-button.p-button-sm .p-button-icon {
      font-size: 0.875rem; }
  .p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.8925rem 1.25rem; }
    .p-button.p-button-lg .p-button-icon {
      font-size: 1.25rem; }

.p-fluid .p-button {
  width: 100%; }
.p-fluid .p-button-icon-only {
  width: 3rem; }
.p-fluid .p-buttonset {
  display: flex; }
  .p-fluid .p-buttonset .p-button {
    flex: 1; }

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #121212;
  background: #F48FB1;
  border: 0 none; }
  .p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: rgba(244, 143, 177, 0.92);
    color: #121212;
    border-color: transparent; }
  .p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    box-shadow: none; }
  .p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: rgba(244, 143, 177, 0.68);
    color: #121212;
    border-color: transparent; }
  .p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #F48FB1;
    border: 1px solid; }
    .p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
      background: rgba(244, 143, 177, 0.04);
      color: #F48FB1;
      border: 1px solid; }
    .p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
      background: rgba(244, 143, 177, 0.16);
      color: #F48FB1;
      border: 1px solid; }
  .p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #F48FB1;
    border-color: transparent; }
    .p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
      background: rgba(244, 143, 177, 0.04);
      border-color: transparent;
      color: #F48FB1; }
    .p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
      background: rgba(244, 143, 177, 0.16);
      border-color: transparent;
      color: #F48FB1; }

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #212121;
  background: #90CAF9;
  border: 0 none; }
  .p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
    background: rgba(144, 202, 249, 0.92);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
    box-shadow: none; }
  .p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
    background: rgba(144, 202, 249, 0.68);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #90CAF9;
    border: 1px solid; }
    .p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
      background: rgba(144, 202, 249, 0.04);
      color: #90CAF9;
      border: 1px solid; }
    .p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
      background: rgba(144, 202, 249, 0.16);
      color: #90CAF9;
      border: 1px solid; }
  .p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #90CAF9;
    border-color: transparent; }
    .p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
      background: rgba(144, 202, 249, 0.04);
      border-color: transparent;
      color: #90CAF9; }
    .p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
      background: rgba(144, 202, 249, 0.16);
      border-color: transparent;
      color: #90CAF9; }

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #212121;
  background: #C5E1A5;
  border: 0 none; }
  .p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: rgba(197, 225, 165, 0.92);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
    box-shadow: none; }
  .p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
    background: rgba(197, 225, 165, 0.68);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #C5E1A5;
    border: 1px solid; }
    .p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
      background: rgba(197, 225, 165, 0.04);
      color: #C5E1A5;
      border: 1px solid; }
    .p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
      background: rgba(197, 225, 165, 0.16);
      color: #C5E1A5;
      border: 1px solid; }
  .p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #C5E1A5;
    border-color: transparent; }
    .p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
      background: rgba(197, 225, 165, 0.04);
      border-color: transparent;
      color: #C5E1A5; }
    .p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
      background: rgba(197, 225, 165, 0.16);
      border-color: transparent;
      color: #C5E1A5; }

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #212121;
  background: #FFF59D;
  border: 0 none; }
  .p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
    background: rgba(255, 245, 157, 0.92);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
    box-shadow: none; }
  .p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
    background: rgba(255, 245, 157, 0.68);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #FFF59D;
    border: 1px solid; }
    .p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
      background: rgba(255, 245, 157, 0.04);
      color: #FFF59D;
      border: 1px solid; }
    .p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
      background: rgba(255, 245, 157, 0.16);
      color: #FFF59D;
      border: 1px solid; }
  .p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #FFF59D;
    border-color: transparent; }
    .p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
      background: rgba(255, 245, 157, 0.04);
      border-color: transparent;
      color: #FFF59D; }
    .p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
      background: rgba(255, 245, 157, 0.16);
      border-color: transparent;
      color: #FFF59D; }

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #212121;
  background: #CE93D8;
  border: 0 none; }
  .p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
    background: rgba(206, 147, 216, 0.92);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
    box-shadow: none; }
  .p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
    background: rgba(206, 147, 216, 0.68);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #CE93D8;
    border: 1px solid; }
    .p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
      background: rgba(206, 147, 216, 0.04);
      color: #CE93D8;
      border: 1px solid; }
    .p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
      background: rgba(206, 147, 216, 0.16);
      color: #CE93D8;
      border: 1px solid; }
  .p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #CE93D8;
    border-color: transparent; }
    .p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
      background: rgba(206, 147, 216, 0.04);
      border-color: transparent;
      color: #CE93D8; }
    .p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
      background: rgba(206, 147, 216, 0.16);
      border-color: transparent;
      color: #CE93D8; }

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #212121;
  background: #EF9A9A;
  border: 0 none; }
  .p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: rgba(239, 154, 154, 0.92);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
    box-shadow: none; }
  .p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
    background: rgba(239, 154, 154, 0.68);
    color: #212121;
    border-color: transparent; }
  .p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #EF9A9A;
    border: 1px solid; }
    .p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
      background: rgba(239, 154, 154, 0.04);
      color: #EF9A9A;
      border: 1px solid; }
    .p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
      background: rgba(239, 154, 154, 0.16);
      color: #EF9A9A;
      border: 1px solid; }
  .p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #EF9A9A;
    border-color: transparent; }
    .p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
      background: rgba(239, 154, 154, 0.04);
      border-color: transparent;
      color: #EF9A9A; }
    .p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
      background: rgba(239, 154, 154, 0.16);
      border-color: transparent;
      color: #EF9A9A; }

.p-button.p-button-link {
  color: transparent;
  background: transparent;
  border: transparent; }
  .p-button.p-button-link:enabled:hover {
    background: transparent;
    color: transparent;
    border-color: transparent; }
    .p-button.p-button-link:enabled:hover .p-button-label {
      text-decoration: underline; }
  .p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: none;
    border-color: transparent; }
  .p-button.p-button-link:enabled:active {
    background: transparent;
    color: transparent;
    border-color: transparent; }

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem; }
  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
  .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: rgba(255, 255, 255, 0.6);
    border-color: transparent;
    background: rgba(255, 255, 255, 0.04); }
  .p-carousel .p-carousel-content .p-carousel-prev:focus,
  .p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }
.p-carousel .p-carousel-indicators {
  padding: 1rem; }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem; }
    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
      background-color: rgba(255, 255, 255, 0.3);
      width: 2rem;
      height: 0.5rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 0; }
      .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
        background: rgba(255, 255, 255, 0.6); }
    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background: rgba(159, 168, 218, 0.16);
      color: #9FA8DA; }

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0; }
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0; }
.p-datatable .p-datatable-header {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500; }
.p-datatable .p-datatable-footer {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid #404040;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500; }
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #404040;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
  background: #1e1e1e;
  transition: none; }
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid 3404040;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
  background: #1e1e1e; }
.p-datatable .p-sortable-column {
  outline-color: transparent; }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: rgba(255, 255, 255, 0.6);
    margin-left: 0; }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #9FA8DA;
    background: rgba(159, 168, 218, 0.16);
    margin-left: 0.5rem; }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.04);
    color: rgba(255, 255, 255, 0.87); }
    .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-datatable .p-sortable-column.p-highlight {
    background: #1e1e1e;
    color: rgba(255, 255, 255, 0.87); }
    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.87); }
.p-datatable .p-datatable-tbody > tr {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  transition: none;
  outline-color: transparent; }
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #404040;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem; }
    .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
      width: 2.5rem;
      height: 2.5rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
      .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
        color: rgba(255, 255, 255, 0.6);
        border-color: transparent;
        background: rgba(255, 255, 255, 0.04); }
      .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
      .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
      margin-right: 0.5rem; }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: rgba(159, 168, 218, 0.16);
    color: #9FA8DA; }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 rgba(159, 168, 218, 0.16); }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 rgba(159, 168, 218, 0.16); }
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.87); }
  
.p-datatable .p-column-title {
  font-size: 0.875rem; }
.p-datatable .p-column-resizer-helper {
  background: #9FA8DA; }
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #1e1e1e; }
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem; }
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px; }
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px; }
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px; }
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px; }
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px; }
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px; }
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px; }
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: rgba(255, 255, 255, 0.02); }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: rgba(159, 168, 218, 0.16);
    color: #9FA8DA; }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
      color: #9FA8DA; }
      .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
        color: #9FA8DA; }
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem; }
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem; }
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem; }
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem; }
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem; }
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem; }
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem; }
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem; }
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem; }
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem; }

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0; }
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0; }
.p-dataview .p-dataview-header {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500; }
.p-dataview .p-dataview-content {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  padding: 1rem 0; }
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0; }
.p-dataview .p-dataview-footer {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid #404040;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem; }

.p-datascroller .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0; }
.p-datascroller .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0; }
.p-datascroller .p-datascroller-header {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500; }
.p-datascroller .p-datascroller-content {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  padding: 1rem 0; }
.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
  border: solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0; }
.p-datascroller .p-datascroller-footer {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid #404040;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.fc .fc-view-container th {
  background: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87); }
.fc .fc-view-container td.fc-widget-content {
  background: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87); }
.fc .fc-view-container td.fc-head-container {
  border: 1px solid rgba(255, 255, 255, 0.12); }
.fc .fc-view-container .fc-row {
  border-right: 1px solid rgba(255, 255, 255, 0.12); }
.fc .fc-view-container .fc-event {
  background: rgba(159, 168, 218, 0.16);
  border: 1px solid rgba(159, 168, 218, 0.16);
  color: #9FA8DA; }
.fc .fc-view-container .fc-divider {
  background: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.12); }
.fc .fc-toolbar .fc-button {
  color: #121212;
  background: #9FA8DA;
  border: 0 none;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
  display: flex;
  align-items: center; }
  .fc .fc-toolbar .fc-button:hover {
    background: rgba(159, 168, 218, 0.92);
    color: #121212;
    border-color: transparent; }
  .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: 'PrimeIcons' !important;
    text-indent: 0;
    font-size: 1rem; }
    .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
      content: ""; }
  .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: 'PrimeIcons' !important;
    text-indent: 0;
    font-size: 1rem; }
    .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
      content: ""; }
  .fc .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }
  .fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #2f2f2f;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
    .fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
      background: #373737;
      border-color: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.87); }
    .fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
      background: #1c1c1c;
      border-color: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.87); }
      .fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
        background: #262626;
        border-color: rgba(255, 255, 255, 0.12);
        color: rgba(255, 255, 255, 0.87); }
    .fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      z-index: 1; }
.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0; }
  .fc .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .fc .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }

.p-orderlist .p-orderlist-controls {
  padding: 1rem; }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem; }
.p-orderlist .p-orderlist-header {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 1rem;
  font-weight: 500;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
.p-orderlist .p-orderlist-list {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  padding: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 1rem 1rem;
    margin: 0;
    border: 0 none;
    color: rgba(255, 255, 255, 0.87);
    background: transparent;
    transition: transform 0.2s, none; }
    .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.04);
      color: rgba(255, 255, 255, 0.87); }
    .p-orderlist .p-orderlist-list .p-orderlist-item:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
    .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
      color: #9FA8DA;
      background: rgba(159, 168, 218, 0.16); }

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column; }
    .p-orderlist .p-orderlist-controls {
      padding: 1rem;
      flex-direction: row; }
      .p-orderlist .p-orderlist-controls .p-button {
        margin-right: 0.5rem;
        margin-bottom: 0; }
        .p-orderlist .p-orderlist-controls .p-button:last-child {
          margin-right: 0; } }
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.87); }
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: rgba(159, 168, 218, 0.16);
  color: #9FA8DA; }
  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: rgba(69, 86, 180, 0.16); }
.p-organizationchart .p-organizationchart-line-down {
  background: rgba(255, 255, 255, 0.12); }
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12); }
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12); }
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  padding: 1rem; }
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%; }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }

.p-paginator {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: solid #404040;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 4px; }
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: rgba(255, 255, 255, 0.6);
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: none;
    border-radius: 50%; }
    .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.04);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.6); }
  .p-paginator .p-paginator-first {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .p-paginator .p-paginator-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    height: 3rem; }
    .p-paginator .p-dropdown .p-dropdown-label {
      padding-right: 0; }
  .p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: rgba(255, 255, 255, 0.6);
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    padding: 0 0.5rem; }
  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: rgba(255, 255, 255, 0.6);
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: none;
    border-radius: 50%; }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
      background: rgba(159, 168, 218, 0.16);
      border-color: rgba(159, 168, 218, 0.16);
      color: #9FA8DA; }
    .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.04);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.6); }

.p-picklist .p-picklist-buttons {
  padding: 1rem; }
  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem; }
.p-picklist .p-picklist-header {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 1rem;
  font-weight: 500;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
.p-picklist .p-picklist-list {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  padding: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
  .p-picklist .p-picklist-list .p-picklist-item {
    padding: 1rem 1rem;
    margin: 0;
    border: 0 none;
    color: rgba(255, 255, 255, 0.87);
    background: transparent;
    transition: transform 0.2s, none; }
    .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
      background: rgba(255, 255, 255, 0.04);
      color: rgba(255, 255, 255, 0.87); }
    .p-picklist .p-picklist-list .p-picklist-item:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
    .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
      color: #9FA8DA;
      background: rgba(159, 168, 218, 0.16); }

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column; }
    .p-picklist .p-picklist-buttons {
      padding: 1rem;
      flex-direction: row; }
      .p-picklist .p-picklist-buttons .p-button {
        margin-right: 0.5rem;
        margin-bottom: 0; }
        .p-picklist .p-picklist-buttons .p-button:last-child {
          margin-right: 0; }
    .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
      content: ""; }
    .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
      content: ""; }
    .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
      content: ""; }
    .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
      content: ""; } }
.p-tree {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  padding: 1rem;
  border-radius: 4px; }
  .p-tree .p-tree-container .p-treenode {
    padding: 0.25rem; }
    .p-tree .p-tree-container .p-treenode .p-treenode-content {
      border-radius: 4px;
      transition: none;
      padding: 0.75rem; }
      .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
        margin-right: 0.5rem;
        width: 2.5rem;
        height: 2.5rem;
        color: rgba(255, 255, 255, 0.6);
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
        .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
          color: rgba(255, 255, 255, 0.6);
          border-color: transparent;
          background: rgba(255, 255, 255, 0.04); }
        .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none; }
      .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
        margin-right: 0.5rem;
        color: rgba(255, 255, 255, 0.6); }
      .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
        margin-right: 0.5rem; }
        .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
          color: rgba(255, 255, 255, 0.87); }
      .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
      .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
        background: rgba(159, 168, 218, 0.16);
        color: #9FA8DA; }
        .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
        .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
          color: #9FA8DA; }
      .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background: rgba(255, 255, 255, 0.04);
        color: rgba(255, 255, 255, 0.87); }
  .p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem; }
    .p-tree .p-tree-filter-container .p-tree-filter {
      width: 100%;
      padding-right: 2rem; }
    .p-tree .p-tree-filter-container .p-tree-filter-icon {
      right: 1rem;
      color: rgba(255, 255, 255, 0.6); }
  .p-tree .p-treenode-children {
    padding: 0 0 0 1rem; }
  .p-tree .p-tree-loading-icon {
    font-size: 2rem; }

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0; }
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0; }
.p-treetable .p-treetable-header {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500; }
.p-treetable .p-treetable-footer {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid #404040;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500; }
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #404040;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
  background: #1e1e1e;
  transition: none; }
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid 3404040;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
  background: #1e1e1e; }
.p-treetable .p-sortable-column {
  outline-color: transparent; }
  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: rgba(255, 255, 255, 0.6);
    margin-left: 0.5rem; }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #9FA8DA;
    background: rgba(159, 168, 218, 0.16);
    margin-left: 0.5rem; }
  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.04);
    color: rgba(255, 255, 255, 0.87); }
    .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-treetable .p-sortable-column.p-highlight {
    background: #1e1e1e;
    color: rgba(255, 255, 255, 0.87); }
    .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: rgba(255, 255, 255, 0.87); }
.p-treetable .p-treetable-tbody > tr {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  transition: none;
  outline-color: transparent; }
  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid #404040;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem; }
    .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
      width: 2.5rem;
      height: 2.5rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem; }
      .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
        color: rgba(255, 255, 255, 0.6);
        border-color: transparent;
        background: rgba(255, 255, 255, 0.04); }
      .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
      .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
        margin-right: 0.5rem; }
        .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
          color: rgba(255, 255, 255, 0.87); }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: rgba(159, 168, 218, 0.16);
    color: #9FA8DA; }
    .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
      color: #9FA8DA; }
      .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
        color: #9FA8DA; }
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.87); }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: rgba(255, 255, 255, 0.87); }
.p-treetable .p-column-resizer-helper {
  background: #9FA8DA; }
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #1e1e1e; }
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem; }
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px; }
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px; }
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px; }
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px; }
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px; }
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px; }
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px; }
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem; }
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem; }
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem; }
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem; }
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem; }
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem; }
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem; }
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem; }
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem; }
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem; }

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.5rem;
  border: 0 none;
  color: rgba(255, 255, 255, 0.87);
  background: #1e1e1e;
  font-weight: 400;
  border-radius: 4px;
  transition: none; }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0.5rem; }
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none; }
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: rgba(255, 255, 255, 0.04);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.87); }
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #1e1e1e;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.87);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.87); }
.p-accordion .p-accordion-content {
  padding: 1rem 1.5rem;
  border: 0 none;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
.p-accordion .p-accordion-tab {
  margin-bottom: 0; }
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    border-radius: 0; }
  .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border-top: 0 none; }
  .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0 none; }
  .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
  .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }

.p-card {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px; }
  .p-card .p-card-body {
    padding: 1rem; }
  .p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem; }
  .p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: rgba(255, 255, 255, 0.6); }
  .p-card .p-card-content {
    padding: 1rem 0; }
  .p-card .p-card-footer {
    padding: 1rem 0 0 0; }

.p-fieldset {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 4px; }
  .p-fieldset .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
    background: #1e1e1e;
    font-weight: 500;
    border-radius: 4px; }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
      padding: 1rem;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 4px;
      transition: none; }
      .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
        margin-right: 0.5rem; }
      .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
      background: rgba(255, 255, 255, 0.04);
      border-color: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.87); }
  .p-fieldset .p-fieldset-content {
    padding: 1rem; }

.p-panel .p-panel-header {
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 1rem;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
  .p-panel .p-panel-header .p-panel-title {
    font-weight: 500; }
  .p-panel .p-panel-header .p-panel-header-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(255, 255, 255, 0.6);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
      color: rgba(255, 255, 255, 0.6);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.04); }
    .p-panel .p-panel-header .p-panel-header-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem; }
.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0 none; }
.p-panel .p-panel-footer {
  padding: 1rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border-top: 0 none; }

.p-scrollpanel .p-scrollpanel-bar {
  background: rgba(255, 255, 255, 0.12);
  border: 0 none; }

.p-tabview .p-tabview-nav {
  background: transparent;
  border: solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0; }
  .p-tabview .p-tabview-nav li {
    margin-right: 0; }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      border: none;
      border-width: 0 0 0 0;
      border-color: transparent transparent transparent transparent;
      background: transparent;
      color: rgba(255, 255, 255, 0.6);
      padding: 1rem 1.5rem;
      font-weight: 500;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      transition: none;
      margin: 0 0 0 0; }
      .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
    .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      background: rgba(159, 168, 218, 0.04);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.6); }
    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: transparent;
      border-color: transparent;
      color: #9FA8DA; }
.p-tabview .p-tabview-panels {
  background: transparent;
  padding: 1rem;
  border: 0 none;
  color: rgba(255, 255, 255, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.p-toolbar {
  background: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 1rem;
  border-radius: 4px; }
  .p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem; }

.p-dialog {
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none; }
  .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #262626;
    color: rgba(255, 255, 255, 0.87);
    padding: 1.5rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .p-dialog .p-dialog-header .p-dialog-title {
      font-weight: 500;
      font-size: 1.25rem; }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
      width: 2.5rem;
      height: 2.5rem;
      color: rgba(255, 255, 255, 0.6);
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem; }
      .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
        color: rgba(255, 255, 255, 0.6);
        border-color: transparent;
        background: rgba(255, 255, 255, 0.04); }
      .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
      .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
        margin-right: 0; }
  .p-dialog .p-dialog-content {
    background: #262626;
    color: rgba(255, 255, 255, 0.87);
    padding: 0 1.5rem 1.5rem 1.5rem; }
  .p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #262626;
    color: rgba(255, 255, 255, 0.87);
    padding: 1rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
    .p-dialog .p-dialog-footer button {
      margin: 0 0.5rem 0 0;
      width: auto; }

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.32); }

.p-overlaypanel {
  background: #262626;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12); }
  .p-overlaypanel .p-overlaypanel-content {
    padding: 1rem; }
  .p-overlaypanel .p-overlaypanel-close {
    background: #9FA8DA;
    color: #121212;
    width: 2.5rem;
    height: 2.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1.25rem;
    right: -1.25rem; }
    .p-overlaypanel .p-overlaypanel-close:enabled:hover {
      background: rgba(159, 168, 218, 0.92);
      color: #121212; }
  .p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(38, 38, 38, 0);
    border-bottom-color: #262626; }
  .p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(38, 38, 38, 0);
    border-bottom-color: #262626; }
  .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #262626; }
  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #262626; }

.p-sidebar {
  background: #262626;
  color: rgba(255, 255, 255, 0.87);
  padding: 1rem;
  border: 0 none;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12); }
  .p-sidebar .p-sidebar-close,
  .p-sidebar .p-sidebar-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(255, 255, 255, 0.6);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
    .p-sidebar .p-sidebar-close:enabled:hover,
    .p-sidebar .p-sidebar-icon:enabled:hover {
      color: rgba(255, 255, 255, 0.6);
      border-color: transparent;
      background: rgba(255, 255, 255, 0.04); }
    .p-sidebar .p-sidebar-close:focus,
    .p-sidebar .p-sidebar-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.32); }

.p-tooltip .p-tooltip-text {
  background: #444444;
  color: rgba(255, 255, 255, 0.87);
  padding: 0.5rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px; }
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #444444; }
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #444444; }
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #444444; }
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #444444; }

.p-fileupload .p-fileupload-buttonbar {
  background: #1e1e1e;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem; }
    .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
.p-fileupload .p-fileupload-content {
  background: #1e1e1e;
  padding: 2rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
.p-fileupload .p-progressbar {
  height: 4px; }
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem; }
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0; }

.p-fileupload-choose:not(.p-disabled):hover {
  background: rgba(159, 168, 218, 0.92);
  color: #121212;
  border-color: transparent; }
.p-fileupload-choose:not(.p-disabled):active {
  background: rgba(159, 168, 218, 0.68);
  color: #121212;
  border-color: transparent; }

.p-breadcrumb {
  background: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  padding: 1rem; }
  .p-breadcrumb ul li .p-menuitem-link {
    transition: none;
    border-radius: 4px; }
    .p-breadcrumb ul li .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
    .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5rem 0 0.5rem;
    color: rgba(255, 255, 255, 0.6); }
  .p-breadcrumb ul li:last-child .p-menuitem-text {
    color: rgba(255, 255, 255, 0.87); }
  .p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: rgba(255, 255, 255, 0.6); }

.p-contextmenu {
  padding: 0.5rem 0;
  background: #2b2b2b;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem; }
  .p-contextmenu .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none; }
    .p-contextmenu .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-contextmenu .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-contextmenu .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-contextmenu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-contextmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: rgba(255, 255, 255, 0.04); }
    .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-contextmenu .p-menu-separator {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    margin: 0.5rem 0; }
  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem; }

.p-megamenu {
  padding: 1rem;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px; }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 4px;
    transition: none;
    user-select: none; }
    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-left: 0.5rem; }
    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.04); }
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-megamenu .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none; }
    .p-megamenu .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-megamenu .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-megamenu .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-megamenu .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-megamenu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-megamenu .p-megamenu-panel {
    background: #2b2b2b;
    color: rgba(255, 255, 255, 0.87);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 1rem;
    color: rgba(255, 255, 255, 0.6);
    background: transparent;
    font-weight: 400;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
  .p-megamenu .p-megamenu-submenu {
    padding: 0.5rem 0;
    width: 12.5rem; }
    .p-megamenu .p-megamenu-submenu .p-menu-separator {
      border-top: 1px solid rgba(255, 255, 255, 0.12);
      margin: 0.5rem 0; }
  .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: rgba(255, 255, 255, 0.04); }
    .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.5rem 0; }

.p-menu {
  padding: 0.5rem 0;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  width: 12.5rem; }
  .p-menu .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none; }
    .p-menu .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-menu .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-menu .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-menu .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-menu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-menu.p-menu-overlay {
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .p-menu .p-submenu-header {
    margin: 0;
    padding: 1rem;
    color: rgba(255, 255, 255, 0.6);
    background: transparent;
    font-weight: 400;
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .p-menu .p-menu-separator {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    margin: 0.5rem 0; }

.p-menubar {
  padding: 1rem;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px; }
  .p-menubar .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none; }
    .p-menubar .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-menubar .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-menubar .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-menubar .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-menubar .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 4px;
    transition: none;
    user-select: none; }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-left: 0.5rem; }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.04); }
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-menubar .p-submenu-list {
    padding: 0.5rem 0;
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 12.5rem; }
    .p-menubar .p-submenu-list .p-menu-separator {
      border-top: 1px solid rgba(255, 255, 255, 0.12);
      margin: 0.5rem 0; }
    .p-menubar .p-submenu-list .p-submenu-icon {
      font-size: 0.875rem; }
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: rgba(255, 255, 255, 0.04); }
    .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative; }
    .p-menubar .p-menubar-button {
      display: flex;
      width: 2.5rem;
      height: 2.5rem;
      color: rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
      .p-menubar .p-menubar-button:hover {
        color: rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.04); }
      .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
    .p-menubar .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.5rem 0;
      background: #2b2b2b;
      border: 0 none;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      width: 100%; }
      .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid rgba(255, 255, 255, 0.12);
        margin: 0.5rem 0; }
      .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem; }
      .p-menubar .p-menubar-root-list > .p-menuitem {
        width: 100%;
        position: static; }
        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
          padding: 1rem 1rem;
          color: rgba(255, 255, 255, 0.87);
          border-radius: 0;
          transition: none;
          user-select: none; }
          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
            color: rgba(255, 255, 255, 0.87); }
          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
            color: rgba(255, 255, 255, 0.6);
            margin-right: 0.5rem; }
          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
            color: rgba(255, 255, 255, 0.6); }
          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
            background: rgba(255, 255, 255, 0.04); }
            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
              color: rgba(255, 255, 255, 0.87); }
            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
              color: rgba(255, 255, 255, 0.6); }
            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
              color: rgba(255, 255, 255, 0.6); }
          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none; }
          .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
            margin-left: auto;
            transition: transform 0.2s; }
        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
          transform: rotate(-180deg); }
      .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none; }
        .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
          transition: transform 0.2s;
          transform: rotate(90deg); }
        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
          transform: rotate(-90deg); }
      .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static; }
      .p-menubar .p-menubar-root-list ul li a {
        padding-left: 3rem; }
      .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 5rem; }
      .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 7rem; }
      .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 9rem; }
      .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 11rem; }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1; } }
.p-panelmenu .p-panelmenu-header > a {
  padding: 1.5rem;
  border: 0 none;
  color: rgba(255, 255, 255, 0.87);
  background: #1e1e1e;
  font-weight: 400;
  border-radius: 4px;
  transition: none; }
  .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: 0.5rem; }
  .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: 0.5rem; }
  .p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: rgba(255, 255, 255, 0.04);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.87); }
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0; }
  .p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #1e1e1e;
    border-color: transparent;
    color: rgba(255, 255, 255, 0.87);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: transparent;
    background: rgba(255, 255, 255, 0.04);
    color: rgba(255, 255, 255, 0.87); }
.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 0 none;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none; }
    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
      margin-right: 0.5rem; }
  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem; }
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0; }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
    border-radius: 0; }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0; }
  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
    border-top: 0 none; }
  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
    border-top: 0 none; }
  .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }

.p-slidemenu {
  padding: 0.5rem 0;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  width: 12.5rem; }
  .p-slidemenu .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none; }
    .p-slidemenu .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-slidemenu .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-slidemenu .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-slidemenu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-slidemenu.p-slidemenu-overlay {
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .p-slidemenu .p-slidemenu-list {
    padding: 0.5rem 0;
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: rgba(255, 255, 255, 0.04); }
    .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    margin: 0.5rem 0; }
  .p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem; }
  .p-slidemenu .p-slidemenu-backward {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87); }

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: none;
  border-radius: 4px;
  background: transparent; }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: rgba(255, 255, 255, 0.87);
    border: 1px solid transparent;
    background: transparent;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%; }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: rgba(255, 255, 255, 0.87); }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: rgba(159, 168, 218, 0.16);
  color: #9FA8DA; }
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87); }
.p-steps .p-steps-item:before {
  content: ' ';
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem; }

.p-tabmenu .p-tabmenu-nav {
  background: transparent;
  border: solid rgba(255, 255, 255, 0.12);
  border-width: 0 0 1px 0; }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0; }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      border: none;
      border-width: 0 0 0 0;
      border-color: transparent transparent transparent transparent;
      background: transparent;
      color: rgba(255, 255, 255, 0.6);
      padding: 1rem 1.5rem;
      font-weight: 500;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      transition: none;
      margin: 0 0 0 0; }
      .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
        margin-right: 0.5rem; }
      .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
      background: rgba(159, 168, 218, 0.04);
      border-color: transparent;
      color: rgba(255, 255, 255, 0.6); }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      background: transparent;
      border-color: transparent;
      color: #9FA8DA; }

.p-tieredmenu {
  padding: 0.5rem 0;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  width: 12.5rem; }
  .p-tieredmenu .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none; }
    .p-tieredmenu .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 0.5rem; }
    .p-tieredmenu .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
    .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
      background: rgba(255, 255, 255, 0.04); }
      .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: rgba(255, 255, 255, 0.87); }
      .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: rgba(255, 255, 255, 0.6); }
      .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: rgba(255, 255, 255, 0.6); }
    .p-tieredmenu .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-tieredmenu.p-tieredmenu-overlay {
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .p-tieredmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: rgba(255, 255, 255, 0.04); }
    .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
      color: rgba(255, 255, 255, 0.87); }
    .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
      color: rgba(255, 255, 255, 0.6); }
  .p-tieredmenu .p-menu-separator {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    margin: 0.5rem 0; }
  .p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem; }

.p-inline-message {
  padding: 1rem 1rem;
  margin: 0;
  border-radius: 4px; }
  .p-inline-message.p-inline-message-info {
    background: #B3E5FC;
    border: solid transparent;
    border-width: 1px;
    color: #01579B; }
    .p-inline-message.p-inline-message-info .p-inline-message-icon {
      color: #01579B; }
  .p-inline-message.p-inline-message-success {
    background: #C8E6C9;
    border: solid transparent;
    border-width: 1px;
    color: #1B5E20; }
    .p-inline-message.p-inline-message-success .p-inline-message-icon {
      color: #1B5E20; }
  .p-inline-message.p-inline-message-warn {
    background: #FFECB3;
    border: solid transparent;
    border-width: 1px;
    color: #7f6003; }
    .p-inline-message.p-inline-message-warn .p-inline-message-icon {
      color: #7f6003; }
  .p-inline-message.p-inline-message-error {
    background: #FFCDD2;
    border: solid transparent;
    border-width: 1px;
    color: #B71C1C; }
    .p-inline-message.p-inline-message-error .p-inline-message-icon {
      color: #B71C1C; }
  .p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem; }
  .p-inline-message .p-inline-message-text {
    font-size: 1rem; }
  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0; }

.p-message {
  margin: 1rem 0;
  border-radius: 4px; }
  .p-message .p-message-wrapper {
    padding: 1.25rem 1.5rem; }
  .p-message .p-message-close {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
    .p-message .p-message-close:hover {
      background: rgba(255, 255, 255, 0.3); }
    .p-message .p-message-close:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }
  .p-message.p-message-info {
    background: #B3E5FC;
    border: solid transparent;
    border-width: 0 0 0 0;
    color: #01579B; }
    .p-message.p-message-info .p-message-icon {
      color: #01579B; }
    .p-message.p-message-info .p-message-close {
      color: #01579B; }
  .p-message.p-message-success {
    background: #C8E6C9;
    border: solid transparent;
    border-width: 0 0 0 0;
    color: #1B5E20; }
    .p-message.p-message-success .p-message-icon {
      color: #1B5E20; }
    .p-message.p-message-success .p-message-close {
      color: #1B5E20; }
  .p-message.p-message-warn {
    background: #FFECB3;
    border: solid transparent;
    border-width: 0 0 0 0;
    color: #7f6003; }
    .p-message.p-message-warn .p-message-icon {
      color: #7f6003; }
    .p-message.p-message-warn .p-message-close {
      color: #7f6003; }
  .p-message.p-message-error {
    background: #FFCDD2;
    border: solid transparent;
    border-width: 0 0 0 0;
    color: #B71C1C; }
    .p-message.p-message-error .p-message-icon {
      color: #B71C1C; }
    .p-message.p-message-error .p-message-close {
      color: #B71C1C; }
  .p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500; }
  .p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem; }

.p-toast {
  opacity: 0.9; }
  .p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px; }
    .p-toast .p-toast-message .p-toast-message-content {
      padding: 1.5rem;
      border-width: 0 0 0 0; }
      .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
        margin: 0 0 0 1rem; }
      .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
        font-size: 2rem; }
      .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
        font-weight: 700; }
      .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
        margin: 0.5rem 0 0 0; }
    .p-toast .p-toast-message .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
      .p-toast .p-toast-message .p-toast-icon-close:hover {
        background: rgba(255, 255, 255, 0.3); }
      .p-toast .p-toast-message .p-toast-icon-close:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none; }
    .p-toast .p-toast-message.p-toast-message-info {
      background: #B3E5FC;
      border: solid transparent;
      border-width: 0 0 0 0;
      color: #01579B; }
      .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
        color: #01579B; }
    .p-toast .p-toast-message.p-toast-message-success {
      background: #C8E6C9;
      border: solid transparent;
      border-width: 0 0 0 0;
      color: #1B5E20; }
      .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
        color: #1B5E20; }
    .p-toast .p-toast-message.p-toast-message-warn {
      background: #FFECB3;
      border: solid transparent;
      border-width: 0 0 0 0;
      color: #7f6003; }
      .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
        color: #7f6003; }
    .p-toast .p-toast-message.p-toast-message-error {
      background: #FFCDD2;
      border: solid transparent;
      border-width: 0 0 0 0;
      color: #B71C1C; }
      .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
        color: #B71C1C; }

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%; }
  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem; }
  .p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.87); }
.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  margin: 0 0.5rem; }
  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
  .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem; }
  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.87); }
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.87);
  padding: 1rem; }
.p-galleria .p-galleria-indicators {
  padding: 1rem; }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: rgba(255, 255, 255, 0.3);
    width: 1.25rem;
    height: 1.25rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%; }
    .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
      background: rgba(255, 255, 255, 0.6); }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: rgba(159, 168, 218, 0.16);
    color: #9FA8DA; }
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem; }
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem; }
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5); }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, 0.3); }
    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
      background: rgba(255, 255, 255, 0.6); }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: rgba(159, 168, 218, 0.16);
    color: #9FA8DA; }
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem; }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.87);
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%; }
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.87); }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
    transition: none; }
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none; }

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9); }

.p-inplace .p-inplace-display {
  padding: 1rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1); }
  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.04);
    color: rgba(255, 255, 255, 0.87); }
  .p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none; }

.p-progressbar {
  border: 0 none;
  height: 4px;
  background: rgba(159, 168, 218, 0.32);
  border-radius: 4px; }
  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #9FA8DA; }
  .p-progressbar .p-progressbar-label {
    color: rgba(255, 255, 255, 0.87);
    line-height: 4px; }

.p-terminal {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 1rem; }
  .p-terminal .p-terminal-input {
    font-size: 1rem;
    font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; }

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.32); }

.p-badge {
  background: #9FA8DA;
  color: #121212;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem; }
  .p-badge.p-badge-secondary {
    background-color: #F48FB1;
    color: #121212; }
  .p-badge.p-badge-success {
    background-color: #C5E1A5;
    color: #212121; }
  .p-badge.p-badge-info {
    background-color: #90CAF9;
    color: #212121; }
  .p-badge.p-badge-warning {
    background-color: #FFF59D;
    color: #212121; }
  .p-badge.p-badge-danger {
    background-color: #EF9A9A;
    color: #212121; }
  .p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem; }
  .p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem; }

.p-tag {
  background: #9FA8DA;
  color: #121212;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px; }
  .p-tag.p-tag-success {
    background-color: #C5E1A5;
    color: #212121; }
  .p-tag.p-tag-info {
    background-color: #90CAF9;
    color: #212121; }
  .p-tag.p-tag-warning {
    background-color: #FFF59D;
    color: #212121; }
  .p-tag.p-tag-danger {
    background-color: #EF9A9A;
    color: #212121; }

.p-accordion .p-accordion-tab {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
  border-radius: 0;
  position: relative;
  transition: margin-bottom 225ms; }
  .p-accordion .p-accordion-tab:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .p-accordion .p-accordion-tab:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .p-accordion .p-accordion-tab .p-accordion-toggle-icon {
    order: 1;
    margin-left: auto;
    transition: transform 0.2s; }
  .p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-header-link:focus {
    background: rgba(255, 255, 255, 0.04); }
  .p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-toggle-icon {
    transform: rotate(-270deg); }
  .p-accordion .p-accordion-tab.p-accordion-tab-active {
    margin-bottom: 1rem; }
    .p-accordion .p-accordion-tab.p-accordion-tab-active .p-accordion-toggle-icon {
      transform: rotate(-180deg); }
  .p-accordion .p-accordion-tab .p-accordion-header.p-disabled {
    opacity: 1; }
    .p-accordion .p-accordion-tab .p-accordion-header.p-disabled .p-accordion-header-link > * {
      opacity: 0.38; }

.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA; }

.p-input-filled .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06) no-repeat;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box; }
  .p-input-filled .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-inputtext {
    background-image: none;
    background: transparent; }
  .p-input-filled .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: transparent;
    background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.87)); }
  .p-input-filled .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus, .p-input-filled .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-inputwrapper-focus {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    background-size: 100% 2px, 100% 1px; }
  .p-input-filled .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-inputtext:enabled:hover, .p-input-filled .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-inputtext:enabled:focus {
    background-image: none;
    background: transparent; }

.p-float-label .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: .25rem 1rem; }

.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding-top: 0;
  padding-bottom: 0; }
  .p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    font-size: 75%; }
.p-input-filled .p-float-label .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0; }

.p-button {
  font-weight: 500;
  min-width: 4rem; }
  .p-button.p-button-icon-only {
    min-width: auto; }
  .p-button:enabled:focus {
    background: rgba(159, 168, 218, 0.76); }
  .p-button:enabled:active {
    background: rgba(159, 168, 218, 0.68); }
  .p-button .p-ink {
    background-color: rgba(255, 255, 255, 0.32); }
  .p-button.p-button-text:enabled:focus, .p-button.p-button-outlined:enabled:focus {
    background: rgba(159, 168, 218, 0.12); }
  .p-button.p-button-text:enabled:active, .p-button.p-button-outlined:enabled:active {
    background: rgba(159, 168, 218, 0.16); }
  .p-button.p-button-text .p-ink, .p-button.p-button-outlined .p-ink {
    background-color: rgba(159, 168, 218, 0.16); }
  .p-button:disabled {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: rgba(255, 255, 255, 0.38) !important;
    opacity: 1; }
    .p-button:disabled.p-button-text {
      background-color: transparent !important;
      color: rgba(255, 255, 255, 0.38) !important; }
    .p-button:disabled.p-button-outlined {
      background-color: transparent !important;
      color: rgba(255, 255, 255, 0.38) !important;
      border-color: rgba(255, 255, 255, 0.38) !important; }
  .p-button.p-button-raised:enabled:focus {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  background: rgba(244, 143, 177, 0.76); }
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: rgba(244, 143, 177, 0.68); }
.p-button.p-button-secondary.p-button-text:enabled:focus, .p-button.p-button-secondary.p-button-outlined:enabled:focus, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:focus {
  background: rgba(244, 143, 177, 0.12); }
.p-button.p-button-secondary.p-button-text:enabled:active, .p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(244, 143, 177, 0.16); }
.p-button.p-button-secondary.p-button-text .p-ink, .p-button.p-button-secondary.p-button-outlined .p-ink, .p-buttonset.p-button-secondary > .p-button.p-button-text .p-ink, .p-buttonset.p-button-secondary > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-secondary > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined .p-ink {
  background-color: rgba(244, 143, 177, 0.16); }

.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  background: rgba(144, 202, 249, 0.76); }
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: rgba(144, 202, 249, 0.68); }
.p-button.p-button-info.p-button-text:enabled:focus, .p-button.p-button-info.p-button-outlined:enabled:focus, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:focus {
  background: rgba(144, 202, 249, 0.12); }
.p-button.p-button-info.p-button-text:enabled:active, .p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(144, 202, 249, 0.16); }
.p-button.p-button-info.p-button-text .p-ink, .p-button.p-button-info.p-button-outlined .p-ink, .p-buttonset.p-button-info > .p-button.p-button-text .p-ink, .p-buttonset.p-button-info > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-info > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-info > .p-button.p-button-outlined .p-ink {
  background-color: rgba(144, 202, 249, 0.16); }

.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  background: rgba(197, 225, 165, 0.76); }
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: rgba(197, 225, 165, 0.68); }
.p-button.p-button-success.p-button-text:enabled:focus, .p-button.p-button-success.p-button-outlined:enabled:focus, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:focus {
  background: rgba(197, 225, 165, 0.12); }
.p-button.p-button-success.p-button-text:enabled:active, .p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(197, 225, 165, 0.16); }
.p-button.p-button-success.p-button-text .p-ink, .p-button.p-button-success.p-button-outlined .p-ink, .p-buttonset.p-button-success > .p-button.p-button-text .p-ink, .p-buttonset.p-button-success > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-success > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-success > .p-button.p-button-outlined .p-ink {
  background-color: rgba(197, 225, 165, 0.16); }

.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  background: rgba(255, 245, 157, 0.76); }
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: rgba(255, 245, 157, 0.68); }
.p-button.p-button-warning.p-button-text:enabled:focus, .p-button.p-button-warning.p-button-outlined:enabled:focus, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:focus {
  background: rgba(255, 245, 157, 0.12); }
.p-button.p-button-warning.p-button-text:enabled:active, .p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 245, 157, 0.16); }
.p-button.p-button-warning.p-button-text .p-ink, .p-button.p-button-warning.p-button-outlined .p-ink, .p-buttonset.p-button-warning > .p-button.p-button-text .p-ink, .p-buttonset.p-button-warning > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-warning > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-warning > .p-button.p-button-outlined .p-ink {
  background-color: rgba(255, 245, 157, 0.16); }

.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  background: rgba(206, 147, 216, 0.76); }
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: rgba(206, 147, 216, 0.68); }
.p-button.p-button-help.p-button-text:enabled:focus, .p-button.p-button-help.p-button-outlined:enabled:focus, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:focus {
  background: rgba(206, 147, 216, 0.12); }
.p-button.p-button-help.p-button-text:enabled:active, .p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(206, 147, 216, 0.16); }
.p-button.p-button-help.p-button-text .p-ink, .p-button.p-button-help.p-button-outlined .p-ink, .p-buttonset.p-button-help > .p-button.p-button-text .p-ink, .p-buttonset.p-button-help > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-help > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-help > .p-button.p-button-outlined .p-ink {
  background-color: rgba(206, 147, 216, 0.16); }

.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  background: rgba(239, 154, 154, 0.76); }
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: rgba(239, 154, 154, 0.68); }
.p-button.p-button-danger.p-button-text:enabled:focus, .p-button.p-button-danger.p-button-outlined:enabled:focus, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:focus, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:focus, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:focus, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:focus {
  background: rgba(239, 154, 154, 0.12); }
.p-button.p-button-danger.p-button-text:enabled:active, .p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(239, 154, 154, 0.16); }
.p-button.p-button-danger.p-button-text .p-ink, .p-button.p-button-danger.p-button-outlined .p-ink, .p-buttonset.p-button-danger > .p-button.p-button-text .p-ink, .p-buttonset.p-button-danger > .p-button.p-button-outlined .p-ink, .p-splitbutton.p-button-danger > .p-button.p-button-text .p-ink, .p-splitbutton.p-button-danger > .p-button.p-button-outlined .p-ink {
  background-color: rgba(239, 154, 154, 0.16); }

.p-calendar-w-btn {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #1e1e1e;
  border-radius: 4px; }
  .p-calendar-w-btn .p-inputtext {
    background-image: none;
    background: transparent;
    border: 0 none; }
    .p-calendar-w-btn .p-inputtext:enabled:focus {
      box-shadow: none; }
  .p-calendar-w-btn .p-datepicker-trigger.p-button {
    background-color: transparent;
    border: 0 none; }
    .p-calendar-w-btn .p-datepicker-trigger.p-button span {
      color: rgba(255, 255, 255, 0.6); }
    .p-calendar-w-btn .p-datepicker-trigger.p-button:enabled:hover {
      background: rgba(255, 255, 255, 0.04); }
    .p-calendar-w-btn .p-datepicker-trigger.p-button:enabled:active, .p-calendar-w-btn .p-datepicker-trigger.p-button:focus {
      background: rgba(255, 255, 255, 0.12); }
  .p-calendar-w-btn:not(.p-disabled):hover {
    border-color: rgba(255, 255, 255, 0.6); }
  .p-calendar-w-btn:not(.p-disabled).p-inputwrapper-focus {
    border-color: #9FA8DA;
    box-shadow: inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA; }

.p-input-filled .p-calendar-w-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06) no-repeat;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box; }
  .p-input-filled .p-calendar-w-btn .p-inputtext {
    background-image: none;
    background: transparent; }
  .p-input-filled .p-calendar-w-btn:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: transparent;
    background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.87)); }
  .p-input-filled .p-calendar-w-btn:not(.p-disabled).p-focus, .p-input-filled .p-calendar-w-btn:not(.p-disabled).p-inputwrapper-focus {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    background-size: 100% 2px, 100% 1px; }
  .p-input-filled .p-calendar-w-btn .p-inputtext:enabled:hover, .p-input-filled .p-calendar-w-btn .p-inputtext:enabled:focus {
    background-image: none;
    background: transparent; }

.p-datepicker .p-datepicker-header {
  border-bottom: 0 none; }
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    margin: 0 auto 0 0;
    order: 1; }
  .p-datepicker .p-datepicker-header .p-datepicker-prev {
    order: 2; }
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    order: 3; }
.p-datepicker table th {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.38);
  font-weight: 400;
  font-size: .875rem; }
.p-datepicker table td.p-datepicker-today > span {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.38); }
.p-datepicker table td.p-datepicker-today.p-highlight {
  box-shadow: 0 0 0 1px rgba(159, 168, 218, 0.16); }

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #F48FB1;
  color: #121212; }

.p-checkbox {
  border-radius: 50%;
  transition: box-shadow 0.2s; }
  .p-checkbox .p-checkbox-box {
    border-color: rgba(255, 255, 255, 0.7);
    border-radius: 2px;
    position: relative; }
    .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
      border-color: rgba(255, 255, 255, 0.7); }
    .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
      border-color: rgba(255, 255, 255, 0.7); }
    .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled).p-focus {
      border-color: #9FA8DA; }
    .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 1px;
      border-right: 2px solid transparent;
      border-bottom: 2px solid transparent;
      transform: rotate(45deg);
      transform-origin: 0% 100%;
      animation: checkbox-check 125ms 50ms linear forwards; }
  .p-checkbox:not(.p-checkbox-disabled):hover {
    box-shadow: 0 0 1px 10px rgba(255, 255, 255, 0.04); }
  .p-checkbox:not(.p-checkbox-disabled).p-checkbox-focused {
    box-shadow: 0 0 1px 10px rgba(255, 255, 255, 0.12); }
  .p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled):hover {
    box-shadow: 0 0 1px 10px rgba(159, 168, 218, 0.04); }
  .p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled).p-checkbox-focused {
    box-shadow: 0 0 1px 10px rgba(159, 168, 218, 0.12); }

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #1e1e1e; }
  .p-input-filled .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
    background-color: #1e1e1e; }

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #121212;
    transform: translate3d(0, 0, 0) rotate(45deg); }
  33% {
    width: 4px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg); }
  100% {
    width: 4px;
    height: 10px;
    border-color: #121212;
    transform: translate3d(0, -10px, 0) rotate(45deg); } }
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA; }

.p-input-filled .p-chips-multiple-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06) no-repeat;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box; }
  .p-input-filled .p-chips-multiple-container .p-inputtext {
    background-image: none;
    background: transparent; }
  .p-input-filled .p-chips-multiple-container:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: transparent;
    background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.87)); }
  .p-input-filled .p-chips-multiple-container:not(.p-disabled).p-focus, .p-input-filled .p-chips-multiple-container:not(.p-disabled).p-inputwrapper-focus {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    background-size: 100% 2px, 100% 1px; }
  .p-input-filled .p-chips-multiple-container .p-inputtext:enabled:hover, .p-input-filled .p-chips-multiple-container .p-inputtext:enabled:focus {
    background-image: none;
    background: transparent; }

.p-float-label .p-chips-multiple-container .p-chips-token {
  padding: .25rem 1rem; }

.p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-token {
  padding-top: 0;
  padding-bottom: 0; }
  .p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    font-size: 75%; }
.p-input-filled .p-float-label .p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0; }

.p-datatable .p-sortable-column {
  outline: 0 none; }
  .p-datatable .p-sortable-column:focus {
    background-color: rgba(255, 255, 255, 0.03); }
.p-datatable .p-datatable-tbody > tr {
  outline: 0 none; }
  .p-datatable .p-datatable-tbody > tr:not(.p-highlight):focus {
    background-color: rgba(255, 255, 255, 0.03); }

.p-dropdown .p-inputtext, .p-dropdown .p-dropdown-trigger {
  background-image: none;
  background: transparent; }
.p-dropdown .p-inputtext {
  border: 0 none; }
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA; }

.p-dropdown-item .p-ink {
  background-color: rgba(159, 168, 218, 0.16); }

.p-input-filled .p-dropdown {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06) no-repeat;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box; }
  .p-input-filled .p-dropdown .p-inputtext {
    background-image: none;
    background: transparent; }
  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: transparent;
    background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.87)); }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus, .p-input-filled .p-dropdown:not(.p-disabled).p-inputwrapper-focus {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    background-size: 100% 2px, 100% 1px; }
  .p-input-filled .p-dropdown .p-inputtext:enabled:hover, .p-input-filled .p-dropdown .p-inputtext:enabled:focus {
    background-image: none;
    background: transparent; }
  .p-input-filled .p-dropdown .p-inputtext {
    border: 0 none; }

.p-galleria .p-galleria-indicators {
  padding: 1rem; }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #F48FB1;
    color: #121212; }
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: rgba(244, 143, 177, 0.68);
  color: #121212; }

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA; }

.p-input-filled .p-inputtext {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06) no-repeat;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box; }
  .p-input-filled .p-inputtext:enabled:hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: transparent;
    background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)); }
  .p-input-filled .p-inputtext:enabled:focus {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    background-size: 100% 2px, 100% 1px; }

.p-input-filled .p-inputgroup .p-inputgroup-addon {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06) no-repeat;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box; }
  .p-input-filled .p-inputgroup .p-inputgroup-addon:last-child {
    border-right-color: transparent; }
.p-input-filled .p-inputgroup-addon:first-child,
.p-input-filled .p-inputgroup button:first-child,
.p-input-filled .p-inputgroup input:first-child,
.p-input-filled .p-inputgroup > .p-inputwrapper:first-child,
.p-input-filled .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-bottom-left-radius: 0; }
.p-input-filled .p-inputgroup .p-float-label:first-child input {
  border-bottom-left-radius: 0; }
.p-input-filled .p-inputgroup-addon:last-child,
.p-input-filled .p-inputgroup button:last-child,
.p-input-filled .p-inputgroup input:last-child,
.p-input-filled .p-inputgroup > .p-inputwrapper:last-child,
.p-input-filled .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-bottom-right-radius: 0; }
.p-input-filled .p-inputgroup .p-float-label:last-child input {
  border-bottom-right-radius: 0; }

.p-inputswitch .p-inputswitch-slider:before {
  transition-property: box-shadow transform;
  box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12); }
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12), 0 0 1px 10px rgba(255, 255, 255, 0.04); }
.p-inputswitch.p-inputswitch-focus .p-inputswitch-slider:before, .p-inputswitch.p-inputswitch-focus:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(255, 255, 255, 0.12), 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12); }
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(159, 168, 218, 0.04), 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12); }
.p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider:before, .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0 0 1px 10px rgba(159, 168, 218, 0.12), 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12); }

.p-fieldset .p-fieldset-legend {
  border: 0 none; }

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -.5rem !important;
  background-color: #1e1e1e;
  padding: 2px 4px;
  margin-left: -4px;
  margin-top: 0; }

.p-float-label textarea ~ label {
  margin-top: 0; }

.p-float-label input:focus ~ label,
.p-float-label .p-inputwrapper-focus ~ label {
  color: #9FA8DA; }

.p-input-filled .p-float-label .p-inputtext {
  padding-top: 1.5rem;
  padding-bottom: .5rem; }
.p-input-filled .p-float-label input:focus ~ label,
.p-input-filled .p-float-label input.p-filled ~ label,
.p-input-filled .p-float-label textarea:focus ~ label,
.p-input-filled .p-float-label textarea.p-filled ~ label,
.p-input-filled .p-float-label .p-inputwrapper-focus ~ label,
.p-input-filled .p-float-label .p-inputwrapper-filled ~ label {
  top: .25rem !important;
  margin-top: 0;
  background: transparent; }

.p-listbox-item .p-ink {
  background-color: rgba(159, 168, 218, 0.16); }

.p-multiselect .p-multiselect-label, .p-multiselect .p-multiselect-trigger {
  background-image: none;
  background: transparent; }
.p-multiselect .p-multiselect-label {
  border: 0 none; }
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA; }

.p-multiselect-item .p-ink {
  background-color: rgba(159, 168, 218, 0.16); }

.p-input-filled .p-multiselect {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06) no-repeat;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box; }
  .p-input-filled .p-multiselect .p-inputtext {
    background-image: none;
    background: transparent; }
  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: transparent;
    background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.87)); }
  .p-input-filled .p-multiselect:not(.p-disabled).p-focus, .p-input-filled .p-multiselect:not(.p-disabled).p-inputwrapper-focus {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    background-size: 100% 2px, 100% 1px; }
  .p-input-filled .p-multiselect .p-inputtext:enabled:hover, .p-input-filled .p-multiselect .p-inputtext:enabled:focus {
    background-image: none;
    background: transparent; }
  .p-input-filled .p-multiselect .p-multiselect-label:hover {
    background-image: none;
    background: transparent; }

.p-overlaypanel .p-overlaypanel-content {
  padding: 1.5rem; }

.p-paginator {
  justify-content: flex-end; }

.p-panel {
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); }
  .p-panel .p-panel-header,
  .p-panel .p-panel-content,
  .p-panel .p-panel-footer {
    border: 0 none; }
  .p-panel .p-panel-content {
    padding-top: 0; }
  .p-panel .p-panel-title {
    font-size: 1.25rem; }

.p-panelmenu .p-panelmenu-panel {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
  border-radius: 0;
  position: relative;
  transition: margin-bottom 225ms; }
  .p-panelmenu .p-panelmenu-panel:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .p-panelmenu .p-panelmenu-panel:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-panelmenu-icon {
    order: 1;
    margin-left: auto;
    margin-right: 0; }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header.p-disabled {
    opacity: 1; }
    .p-panelmenu .p-panelmenu-panel .p-panelmenu-header.p-disabled .p-panelmenu-header-link > * {
      opacity: 0.38; }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header:not(.p-highlight) .p-panelmenu-header-link:focus {
    background: rgba(255, 255, 255, 0.04); }

.p-progressbar {
  border-radius: 0; }
  .p-progressbar .p-progressbar-label {
    display: none; }

.p-radiobutton {
  border-radius: 50%;
  transition: box-shadow 0.2s; }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border: 2px solid rgba(255, 255, 255, 0.7); }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    border: 2px solid rgba(255, 255, 255, 0.7); }
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled).p-focus {
    border-color: #9FA8DA; }
  .p-radiobutton:not(.p-radiobutton-disabled):hover {
    box-shadow: 0 0 1px 10px rgba(255, 255, 255, 0.04); }
  .p-radiobutton:not(.p-radiobutton-disabled).p-radiobutton-focused {
    box-shadow: 0 0 1px 10px rgba(255, 255, 255, 0.12); }
  .p-radiobutton.p-radiobutton-checked:not(.p-radiobutton-disabled):hover {
    box-shadow: 0 0 1px 10px rgba(159, 168, 218, 0.04); }
  .p-radiobutton.p-radiobutton-checked:not(.p-radiobutton-disabled).p-radiobutton-focused {
    box-shadow: 0 0 1px 10px rgba(159, 168, 218, 0.12); }

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #1e1e1e; }
  .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #1e1e1e; }

.p-selectbutton .p-button:focus {
  background: #1c1c1c;
  border-color: rgba(255, 255, 255, 0.12); }
  .p-selectbutton .p-button:focus.p-highlight {
    background: #262626;
    border-color: rgba(255, 255, 255, 0.12); }

.p-slider .p-slider-handle {
  transition: transform 0.2s, box-shadow 0.2s;
  transform: scale(0.7); }
  .p-slider .p-slider-handle:focus {
    box-shadow: 0 0 1px 10px rgba(244, 143, 177, 0.2); }
.p-slider.p-slider-sliding .p-slider-handle {
  transform: scale(1); }

.p-steps {
  padding: 1rem 0; }
  .p-steps .p-steps-item {
    justify-content: flex-start; }
    .p-steps .p-steps-item:before {
      margin-top: 0; }
    .p-steps .p-steps-item .p-menuitem-link {
      flex-direction: row;
      background-color: transparent;
      z-index: 1; }
      .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        background-color: #9e9d9e;
        color: #ffffff;
        margin-right: 0.5rem; }
      .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        margin: 0;
        background-color: transparent;
        color: #9e9d9e;
        padding: 0 .25rem 0 0; }
    .p-steps .p-steps-item.p-highlight .p-steps-number {
      background-color: #9FA8DA;
      color: #121212; }
    .p-steps .p-steps-item.p-highlight .p-steps-title {
      font-weight: 600;
      color: rgba(255, 255, 255, 0.87); }
    .p-steps .p-steps-item.p-disabled {
      opacity: 1; }

.p-tabview .p-tabview-nav {
  position: relative; }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    transition: background-color 0.2s;
    border-radius: 0; }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link > .p-ink {
      background-color: rgba(159, 168, 218, 0.16); }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
      background-color: rgba(159, 168, 218, 0.12); }
  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #9FA8DA;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1); }

.p-toolbar {
  border: 0 none; }

.p-tooltip .p-tooltip-text {
  box-shadow: none;
  font-size: .875rem; }
.p-tooltip .p-tooltip-arrow {
  display: none; }

.p-treetable .p-sortable-column {
  outline: 0 none; }
  .p-treetable .p-sortable-column:focus {
    background-color: rgba(255, 255, 255, 0.03); }
.p-treetable .p-treetable-tbody > tr {
  outline: 0 none; }
  .p-treetable .p-treetable-tbody > tr:not(.p-highlight):focus {
    background-color: rgba(255, 255, 255, 0.03); }

.p-tabmenu .p-tabmenu-nav {
  position: relative; }
  .p-tabmenu .p-tabmenu-nav li .p-menuitem-link {
    transition: background-color 0.2s;
    border-radius: 0; }
    .p-tabmenu .p-tabmenu-nav li .p-menuitem-link > .p-ink {
      background-color: rgba(159, 168, 218, 0.16); }
    .p-tabmenu .p-tabmenu-nav li .p-menuitem-link:focus {
      background-color: rgba(159, 168, 218, 0.12); }
  .p-tabmenu .p-tabmenu-nav .p-tabmenu-ink-bar {
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #9FA8DA;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1); }

.p-togglebutton.p-button:focus {
  background: #1c1c1c;
  border-color: rgba(255, 255, 255, 0.12); }
  .p-togglebutton.p-button:focus.p-highlight {
    background: #262626;
    border-color: rgba(255, 255, 255, 0.12); }

/* Customizations to the designer theme should be defined here */
