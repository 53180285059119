.pi-sort-amount-down:before {
    content: "\e913";
}

.pi-sort-amount-up-alt:before {
    content: "\e914";
}

.pi-sort-alt:before {
    content: "\e915";
}
